<template>
  <div class="contacts">
    <div class="container">
      <div class="contacts__nav">
        <locale-router-link to="/" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link contacts-link"> {{ $t("contacts") }}</a>
      </div>

      <h1 class="contacts__title">{{ $t("contacts") }}</h1>

      <div class="contacts__items">
        <!-- Головной офис -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("headOfficeTitle") }}</h3>
          <address class="contacts__address">
            {{ $t("headOfficeText") }}
          </address>
        </div>
        <!-- Служба доставки Magnum GO -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("deliveryServiceTitle") }}</h3>
          <a :href="`tel:${tel}`" class="contacts__phone">
            {{ tel + $t("contactCenterPhone_3") }}
          </a>
          <!-- <a
            href="https://t.me/MagnumGo_bot"
            target="_blank"
            class="telegram-link"
          >
            <span>Telegram</span>
            <span class="color-pink"> @MagnumGO_bot</span>
          </a> -->
        </div>

        <!-- Служба поддержки -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("supportTitle") }}</h3>
          <a href="tel:7766" class="contacts__phone">
            {{ $t("contactCenterPhone_2") }}</a
          >
        </div>
        <!-- Для предложений поставщиков -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("supplierOffersTitle") }}</h3>
          <a
            href="https://corp.magnum.kz/ru/suppliers"
            target="_blank"
            class="contacts__suggestion-email"
            >kp@magnum.kz</a
          >
        </div>

        <!-- По общим вопросам -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("allQuestionsTitle") }}</h3>
          <a
            href="mailto:info@magnum.kz"
            target="_blank"
            class="contacts__email"
            >info@magnum.kz</a
          >
        </div>

        <!-- Magnum opt. -->
        <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("magnumOpt") }}</h3>
          <a href="https://magnumopt.kz/" target="_blank" class="telegram-link">
            <span class="color-pink"> https://magnumopt.kz </span>
          </a>
          <a
            href="mailto:opt@magnum.kz"
            target="_blank"
            class="telegram-link"
            style="margin: 0"
          >
            <span class="color-pink"> opt@magnum.kz </span>
          </a>
          <a href="tel:77717040347 " class="contacts__phone">
            + 7 771 704 03 47
          </a>
        </div>

        <!-- Возникла проблема с покупкой? -->
        <!-- <div class="contacts__item">
          <h3 class="contacts__item-title">{{ $t("problemsTitle") }}</h3>
          <a
            href="mailto:customerservice@magnum.kz"
            target="_blank"
            class="contacts__email"
            >customerservice@magnum.kz</a
          >
        </div> -->

        <!-- По всем вопросам и предложениям обращаться:  -->
        <div class="contacts__item proposals">
          <h3 class="contacts__item-title contacts__link-title">
            {{ $t("contactSuggestions") }}
          </h3>
          <div v-if="$isMobile()" class="contacts__item-list">
            <locale-router-link
              v-for="(item, index) in emailGroups"
              :key="index"
              :to="`/appeal/${item.id}`"
              class="contacts__link"
            >
              {{ item.attributes.label_ru }}
            </locale-router-link>
          </div>
          <ul v-else class="contacts__item-list">
            <li
              v-for="(item, index) in emailGroups"
              :key="index"
              @click="showAppealModal(item)"
              class="contacts__link"
            >
              {{
                $i18n.locale === "ru"
                  ? item.attributes.label_ru
                  : item.attributes.label_kz
              }}
            </li>
          </ul>
        </div>
        <div class="contacts__item">
          <h3 class="contacts__item-title">
            {{ $t("questionsWork") }}
          </h3>
          <a href="mailto:job@magnum.kz" target="_blank" class="telegram-link">
            <span class="color-pink"> job@magnum.kz </span>
          </a>
        </div>
      </div>
    </div>
    <AppModalAppeal
      v-if="isShowAppealForm"
      @close="isShowAppealForm = false"
      :topic-title="selectedTopic"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/contacts";

import { getNotificationEmailGroups } from "@/api/notification-email-groups";
export default {
  name: "Contacts",
  data() {
    return {
      isShowAppealForm: false,
      selectedTopic: "",
      emailGroups: [],
      tel: "7772",
    };
  },
  components: {
    AppModalAppeal: () => import("@/components/modal/AppModalAppeal.vue"),
  },
  created() {
    getNotificationEmailGroups().then((res) => {
      this.emailGroups = res;
      this.getEmailGroups(res);
    });
  },
  mounted() {
    // let date = new Date().toLocaleString();
    // let firstDate = (date.split(":")[0] + ":" + date.split(":")[1])
    //   .split(",")
    //   .join("");
    // let secondDate = "20.04.2023 00:00";
    // let datetime_regex = /(\d\d)\.(\d\d)\.(\d\d\d\d)\s(\d\d):(\d\d)/;
    // let first_date_arr = datetime_regex.exec(firstDate);
    // let first_datetime = new Date(
    //   first_date_arr[3],
    //   first_date_arr[2],
    //   first_date_arr[1],
    //   first_date_arr[4],
    //   first_date_arr[5]
    // );
    // let second_date_arr = datetime_regex.exec(secondDate);
    // let second_datetime = new Date(
    //   second_date_arr[3],
    //   second_date_arr[2],
    //   second_date_arr[1],
    //   second_date_arr[4],
    //   second_date_arr[5]
    // );
    // console.log(first_datetime.getTime() >= second_datetime.getTime());
    // if (first_datetime.getTime() >= second_datetime.getTime()) {
    //   this.tel = "7777";
    // } else {
    //   this.tel = "7772";
    // }
  },
  methods: {
    ...mapActions("contactsModule", {
      getEmailGroups: actionTypes.getEmailGroups,
    }),
    showAppealModal(item) {
      this.isShowAppealForm = true;
      // this.selectedTopic = item.
      //   .split(" ")
      //   .filter((_, index) => index !== 0)
      //   .join(" ");
      this.selectedTopic = item;
    },
    getSelectTopic(item) {
      console.log(item);
    },
  },
};
</script>
