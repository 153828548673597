import axios from "@/plugins/axios";
import qs from "qs";

export const getNotificationEmailGroups = async () => {
  const query = qs.stringify({
    populate: "*",
    sort: [`order:asc`],
  });
  const response = await axios.get(`/notification-email-groups?${query}`);
  return response.data.data;
};
